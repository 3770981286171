.gantt-table {
  progress {
    appearance: none;
  }

  img {
    position: absolute;
    top: 0;
    right: 0;
    float: right;
    height: 100%;
  }

  tr.gantt-row {
    td,
    th {
      font-weight: 600;
      background-color: $gray-200;
    }
  }

  td {
    position: relative;

    progress {
      position: absolute;
      bottom: 0;
      left: 0;
      width: fill-available;
      height: 4px;
      padding: 0;
      margin: 0;

      &::-webkit-progress-value {
        background-color: $info;
      }

      &::-webkit-progress-bar {
        background-color: #fff;
      }

      &.danger {
        &::-webkit-progress-value {
          background-color: $danger;
        }

        &::-webkit-progress-bar {
          background-color: $danger;
        }
      }

      &.success {
        color: #0f0; // FIXME: use variable

        &::-webkit-progress-value {
          background-color: $success;
        }
      }
    }
  }
}
