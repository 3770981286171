$planner-cell-size: 40px;

.planner {
  &.planner-container {
    position: relative;
    display: flex;
    width: 100%;
    min-height: 200px;
  }

  .freezed-pane {
    display: flex;
    flex-direction: column;
    max-width: 40%;
  }

  .scrollable-pane {
    display: flex;
    flex-direction: column;
    align-items: baseline;
    width: 80%;
    overflow-x: scroll;
  }

  .planner-row {
    display: flex;
    flex-wrap: nowrap;
    height: 40px;
  }

  .planner-cell {
    display: flex;
    flex-shrink: 0;
    align-items: center;
    width: $planner-cell-size;
    height: $planner-cell-size;
    overflow: hidden;
    font-size: .8rem;
  }

  .date-header {
    width: 100%;
    font-size: .75rem;
    line-height: 1;

    &.disabled {
      color: #700;
    }

    span {
      display: inline-block;
      width: 100%;
      text-align: center;
    }
  }

  .first-level-header,
  .second-level-header {
    overflow: hidden;
    font-size: .8rem;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .subtotal {
    position: relative;
    display: flex;
    width: 100%;
    height: 100%;
    font-weight: 700;
    line-height: 1rem;
    cursor: cell;

    &:hover {
      border: 3px solid var(--bs-success);
    }

    &.selected {
      color: var(--bs-light);
      background: var(--bs-success);
    }

    .progress-container {
      position: absolute;
      bottom: 0;
      width: 100%;
      height: 4px;

      .progress-fill {
        height: 100%;
        background-color: var(--bs-success);

        &.incomplete {
          background-color: var(--bs-orange);
        }

        &.overflow {
          background-color: var(--bs-danger);
        }

        &.disabled {
          background-color: var(--bs-gray-500);
        }
      }
    }
  }

  .workload {
    display: flex;
    width: 100%;
    height: 100%;
    cursor: cell;

    &:hover {
      border: 3px solid var(--bs-primary);
    }

    div {
      margin: auto;
    }

    &.selected {
      color: var(--bs-light);
      background: var(--bs-primary);
    }
  }

  .popup {
    position: absolute;
    z-index: 1;
    max-width: 260px;

    &::after {
      position: absolute;
      top: 5px;
      left: -10px;
      display: block;
      width: 0;
      height: 0;
      content: " ";
      border-top: 10px solid transparent;
      border-right: 10px solid var(--bs-white);
      border-bottom: 10px solid transparent;
    }

    &::before {
      position: absolute;
      top: 4px;
      left: -11px;
      display: block;
      width: 0;
      height: 0;
      content: " ";
      border-top: 11px solid transparent;
      border-right: 11px solid var(--bs-secondary);
      border-bottom: 11px solid transparent;
    }
  }
}
