/*
 * Content:
 *
 * Chart loading
 */


// Chart loading
// ============================================================================

.chart-loading {
  position: absolute;
  z-index: 99;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-color: $white;
  opacity: .8;
}
