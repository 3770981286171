/*
 * Content:
 *
 * btn-block
 */


// btn-block
// ============================================================================

// Improvement: stop using btn-block like in Bootstrap 4  https://getbootstrap.com/docs/5.1/migration/#buttons

$btn-block-spacing-y: .5rem;

.btn-block {
  display: block;
  width: 100%;

  // Vertically space out multiple block buttons
  + .btn-block {
    margin-top: $btn-block-spacing-y;
  }
}
