/*
 * Content:
 *
 * Headers
 * Links
 */


// Headers
// ============================================================================

h1,
.h1 {
  font-weight: 300;
  line-height: $display-line-height;
}

h2,
.h2 {
  font-weight: 300;
  line-height: $display-line-height;
}

h3,
.h3 {
  font-weight: 400;
  line-height: $display-line-height;
}

h4,
.h4 {
  font-weight: 400;
  line-height: $display-line-height;
}

h5,
.h5 {
  font-weight: 500;
  line-height: $display-line-height;
}

h6,
.h6 {
  font-weight: 500;
  line-height: $display-line-height;
}


// Links without underline like Bootstrap 4
// ============================================================================

a:not([class*="btn"]) {
  text-decoration: none;
}

a:not([class*="btn"]):hover {
  text-decoration: underline;
}
