.execution-report {
  .progress,
  .progress-bar,
  .back-text {
    height: 1.25rem;
  }

  .thumbnail {
    height: 1.5rem;
    margin-right: .5rem;
  }

  .back-text {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  tr.report-rate-perfect {
    td {
      color: #070;
    }

    .progress-bar {
      background-color: #070;
    }
  }

  tr.report-rate-good {
    td {
      color: #555d00;
    }

    .progress-bar {
      background-color: #555d00;
    }
  }

  tr.report-rate-low {
    td {
      color: #a55d00;
    }

    .progress-bar {
      background-color: #a55d00;
    }
  }

  tr.report-rate-failed {
    td {
      color: #a00;
    }

    .progress-bar {
      background-color: #a00;
    }
  }
}
