/*
 * Content:
 *
 * Navbar
 * Navbar brand
 * Navbar toggler
 * Navbar hide
 * Navbar dropdown icons
 * Navbar in card
 * Navbar main
 */


// Navbar
// ============================================================================

.navbar {
  min-height: $navbar-min-height;
  box-shadow: 0 -1px 8px 0 $shadow-color;
  transition: transform .5s ease;
}


// Navbar brand
// ============================================================================

.navbar-brand {
  padding: 0;
}


// Navbar toggler
// ============================================================================

.navbar-toggler {
  padding: $navbar-toggler-padding-y px($navbar-toggler-padding-x);
  font-size: px($navbar-toggler-font-size);
  border: none;
}


// Navbar hide
// ============================================================================
.navbar-hide {
  transform: translateY(-100%);
}


// Navbar dropdown icons
// ============================================================================
.nav-item.dropdown a.dropdown-item {
  i {
    width: 1.6em;
    margin-right: spacer(1);
    color: $secondary;
    text-align: center;
  }

  &:hover i {
    color: $white;
  }
}


// Navbar in card
// ============================================================================
.card-header .nav.nav-tabs {
  margin-bottom: -1px; // hack to combine the card header bottom border with the nav-tabs bottom border
  background-color: transparent;

  .nav-link.active {
    background-color: $white;
    border-bottom-color: $white;
  }
}


// Navbar main
// ============================================================================

.navbar-main {
  .navbar-collapse {

    // Main navigation element has a lot of links, so to avoid overflow
    // in edge cases due to viewport width, we reduce the font size when all
    // between problematic breakpoints.
    @include media-breakpoint-between(md, xl) {
      font-size: .9rem;
    }
  }
}
