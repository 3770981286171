@use "sass:math";

/*
 * Content:
 *
 * Footer
 */


// Footer
// ============================================================================

footer.footer {
  min-height: $footer-min-height;
  margin-top: $footer-top-margin;
  background-color: $primary;

  .logo-footer {
    height: math.div($footer-min-height, 1.5);
    margin-top: math.div($footer-min-height, 6);
  }
}
